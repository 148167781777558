import React from "react"
import PageBgSectionHeader from "./PageBgSectionHeader"
import InlineFormWithEmailField from "../molecules/InlineFormWithEmailField"
import ImageRenderer from "../atoms/ImageRenderer"

const BusinessModelsWithFormSection = ({
  title,
  description,
  formSubmitButtonText,
  isShowForm,
}) => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 px-8 sm:px-16 flex flex-col md:gap-10 md:flex-row justify-between items-center sm:items-stretch bg-[#0A1E46] rounded-[30px]">
        <div className="flex-[2] py-10 flex flex-col justify-center">
          <PageBgSectionHeader
            title={title}
            titleEC={"text-white !leading-[1.3em]"}
            description={description}
            descriptionEC={"text-white text-[18px]"}
          />
          {isShowForm && (
            <InlineFormWithEmailField buttonText={formSubmitButtonText} />
          )}
        </div>
        <div className="flex-[1] mt-3 flex justify-end items-center sm:items-end">
          <ImageRenderer img="business-models-mobiles" alt="Image" />
        </div>
      </div>
    </div>
  )
}

export default BusinessModelsWithFormSection
