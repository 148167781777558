import { useEffect, useState } from "react";

const useContentMaxlenghtWithDots = (description, charLength) => {
  const [formattedDescription, setFormattedDescription] = useState("");

  useEffect(() => {
    const maxLength = charLength || 25;
    let formatted = description;
    
    if (description.length > maxLength) {
      formatted = description.substring(0, maxLength) + " ...";
    } else {
      formatted = description.padEnd(maxLength, " ");
    }

    setFormattedDescription(formatted);
  }, [description]);

  return formattedDescription;
};

export default useContentMaxlenghtWithDots;
