export const caseStudiesPageContent = {
  title: `Transforming <span class="text-[#B695F8]">Financial Data</span> Into Business Success`,
  description:
    "SaaS case studies allow companies to highlight specific features and benefits of their product. They can show how these features have been used in real-world scenarios, making them more relatable and understandable.",
  image: {
    path: "circled-pattern",
    alt: "circled-pattern",
  },
}

export const caseStudies = [
  {
    title: `Streamlining <span class="text-[#B695F8]">Financial Management</span> for Small Retail Business`,
    slug: `streamlining-financial-management-for-small-retail-business`,
    category: "Small Business",
    date: '02 Sep, 2024',
    description: `GreenLeaf Boutique, a small retail business, struggled with managing its finances due to a lack of centralised financial tracking and inefficient manual processes. This led to frequent errors, delayed tax filings, and difficulty managing expenses.`,
    client: `GreenLeaf Boutique`,
    industry: `Retail`,
    isLatestTag: true,
    cardImage: "case-study--streamlining-financial-management-for-small-retail-business",
    postContent: [
      {
        image: {
          path:  `case-study-inner--streamlining-financial-management-for-small-retail-business`,
          alt: `case-study-inner--streamlining-financial-management-for-small-retail-business`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `GreenLeaf Boutique, a small retail business, struggled with managing its finances due to a lack of centralised financial tracking and inefficient manual processes. This led to frequent errors, delayed tax filings, and difficulty managing expenses.`,
      },
      {
        title: `Solution`,
        description: `GreenLeaf Boutique implemented Synkli to centralise its financial management. The app provided a comprehensive dashboard that integrated all their financial data, allowing real-time sales, expenses, and profits tracking.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Improved Accuracy:</b> The centralised system reduced errors by 80%, leading to more accurate financial reports.`,
          `<b>Time Savings:</b> Automated processes saved the owner 15 hours per week, which could be redirected towards business growth activities.`,
          `<b>Tax Efficiency:</b> With Synkli’s tax deduction tracking feature, GreenLeaf Boutique maximised their eligible deductions, saving 15% on their annual tax bill.`,
        ],
      },
    ],
  },
  {
    title: `Enhancing <span class="text-[#B695F8]">Accountant-Client</span> Communication for a Consultancy Firm`,
    slug: `enhancing-accountant-client-communication-for-a-consultancy-firm`,
    category: "Accountancy",
    date: '20 Oct, 2024',
    description: `Apex Consultants faced communication barriers regarding financial reporting and tax preparation with their clients. Clients often had difficulty understanding financial statements, leading to repeated queries and extended consultation times.`,
    client: `Apex Consultants`,
    industry: `Professional Services`,
    isLatestTag: true,
    cardImage: "case-study--enhancing-accountant-client-communication-for-a-consultancy-firm",
    postContent: [
      {
        image: {
          path:  `case-study-inner--enhancing-accountant-client-communication-for-a-consultancy-firm`,
          alt: `case-study-inner--enhancing-accountant-client-communication-for-a-consultancy-firm`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `Apex Consultants faced communication barriers regarding financial reporting and tax preparation with their clients. Clients often had difficulty understanding financial statements, leading to repeated queries and extended consultation times.`,
      },
      {
        title: `Solution`,
        description: `Synkli was introduced to Apex Consultants' clients, offering an intuitive platform for viewing and understanding financial data. The app’s clear visualisations and easy-to-use interface facilitated better comprehension of financial reports.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Enhanced Communication:</b> The visual financial reports reduced client queries by 60%, streamlining the consultation process.`,
          `<b>Client Satisfaction:</b> Improved understanding of financial health led to higher client satisfaction and retention rates.`,
          `<b>Efficiency:</b> Accountants at Apex Consultants were able to handle 25% more clients due to reduced time spent on clarifications and explanations.`,
        ],
      },
    ],
  },
  {
    title: `Simplifying Effortless <span class="text-[#B695F8]">Financial Management</span> for a Freelance Graphic Designer`,
    slug: `simplifying-financial-management-for-a-freelance-graphic-designer`,
    category: "Finance",
    date: '15 Aug, 2024',
    description: `As a freelance graphic designer, Emily Stevens struggled with managing her finances, tracking expenses, and preparing for tax season. The lack of financial expertise led to missed deductions and inefficient financial planning.`,
    client: `Emily Stevens Design`,
    industry: `Freelance/Creative Services`,
    isLatestTag: true,
    cardImage: "case-study--simplifying-financial-management-for-a-freelance-graphic-designer",
    postContent: [
      {
        image: {
          path:  `case-study-inner--simplifying-financial-management-for-a-freelance-graphic-designer`,
          alt: `case-study-inner--simplifying-financial-management-for-a-freelance-graphic-designer`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `As a freelance graphic designer, Emily Stevens struggled with managing her finances, tracking expenses, and preparing for tax season. The lack of financial expertise led to missed deductions and inefficient financial planning.`,
      },
      {
        title: `Solution`,
        description: `Emily started using Synkli to track her income, expenses, and potential tax deductions. The app’s user-friendly interface and tailored financial advice helped her stay organised and informed about her financial health.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Financial Organization:</b> Emily was able to categorise and track all her financial transactions efficiently, reducing stress and saving time.`,
          `<b>Tax Savings:</b> By identifying eligible deductions and maintaining accurate records, Emily saved 20% on her taxes.`,
          `<b>Business Growth:</b> With better financial management, Emily could invest more in her business, leading to a 30% increase in her client base over six months.`,
        ],
      },
    ],
  },
]
